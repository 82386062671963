import React, { memo } from 'react';
import { Form, InputNumber } from 'antd';
import Title from 'antd/lib/typography/Title';

const QrSbpModalForm = ({ form, initSum, payment, recPayment }) => {
  return (
    <>
      {payment && recPayment && (
        <div style={{ margin: '0 0 3rem' }}>
          <Title level={5}>Полный платеж: {payment} руб.</Title>
          <Title level={5}>Платеж по графику: {recPayment} руб.</Title>
        </div>
      )}

      <Form
        form={form}
        layout='vertical'
        name='payment_modal'
        size='middle'
        initialValues={{
          Сумма: initSum ? initSum : 0,
        }}
      >
        <Form.Item
          name='Сумма'
          label='Сумма платежа (руб.)'
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: 'Введите сумму',
            },
          ]}
        >
          <InputNumber
            suffix='руб.'
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')}
            parser={(value) => value.replace(/(\s*)руб.?|(\s*)/g, '')}
            min={0.01}
            max={999999.99}
            autoFocus
          />
        </Form.Item>
      </Form>
    </>
  );
};
export default memo(QrSbpModalForm);
